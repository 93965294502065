import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout/Layout";
import RoomTemplate from "./room-template";

const Room = ({ data }) => {
  const { markdownRemark: room } = data;

  return (
    <Layout>
      <Helmet titleTemplate="%s | Room">
        <title>{`${room.frontmatter.title}`}</title>
        <meta
          name="description"
          content={`${room.frontmatter.description}`}
        />
      </Helmet>
      <RoomTemplate
        title={room.frontmatter.title}
        address={room.frontmatter.address}
        description={room.frontmatter.description}
        gallery={room.frontmatter.gallery}
        info={room.frontmatter.info}
        airbnbLink={room.frontmatter.airbnbLink}
        bookingLink={room.frontmatter.bookingLink}
        tags={room.frontmatter.tags}
      />
    </Layout>
  );
};

Room.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Room;

export const pageQuery = graphql`
  query RoomByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        description
        gallery {
          image1 {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image4 {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image5 {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        address {
          street
          city
        }
        info {
          guests
          beds
          wifi
          kitchen
          icon1 {
            childImageSharp {
              fixed(width: 18, height: 18) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          icon2 {
            childImageSharp {
              fixed(width: 18, height: 18) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          icon3 {
            childImageSharp {
              fixed(width: 18, height: 18) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          icon4 {
            childImageSharp {
              fixed(width: 18, height: 18) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        airbnbLink
        bookingLink
        tags
      }
    }
  }
`;
